<template>
  <div>
    <div class="p-3 bg-white rounded-xl shadow-lg mx-4 mb-4">
      <div
        class="flex items-center justify-between"
        @click="showFilters = !showFilters"
      >
        <div class="flex items-center gap-4">
          <a-icon type="filter" class="text-lg ml-2"></a-icon>
          <h2 class="text-left text-lg text-gray-600">
            <b>Filtros de búsqueda</b>
          </h2>
        </div>
        <span
          class="h-5 w-5 m-2 flex justify-center items-center transition justify-self-end"
          :class="{ 'transform rotate-180': showFilters }"
        >
          <a-icon type="caret-down" class="text-xl my-auto" />
        </span>
      </div>
      <a-divider v-if="showFilters" class="mt-2 mb-2"></a-divider>
      <div
        class="flex flex-row gap-2"
        @keyup.enter="onSearch"
        v-show="showFilters"
      >
        <div
          class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-4"
        >
          <BaseDate
            type="text"
            label="Fecha de creación"
            v-model="form.creation_date"
            placeholder="DD/MM/AAAA"
          />
          <BaseSelect
            label="Tipo de criterio"
            :selectOptions="formOptions.optionsCriterions"
            v-model="form.criterion_id"
          />
          <BaseSelect
            label="Tipo de identificación"
            :selectOptions="formOptions.optionsIdentificationType"
            v-model="form.identification_type"
          />
          <BaseInput
            type="text"
            label="Número de documento"
            placeholder="Ej: 1144578546"
            :identification="form.identification_type"
            v-model="form.identification_number"
            required="true"
            :class="!form.identification_type ? 'hidden' : ''"
          />
          <BaseSelect
            ref="company"
            id="entidad"
            label="Entidad"
            :selectOptions="formOptions.optionsCompanies"
            v-model="company"
            :loading="loading"
          />
          <BaseSelect
            v-if="optionsProducts.length === 0"
            ref="product"
            id="producto"
            label="Producto"
            :selectOptions="formOptions.optionsProducts"
            v-model="form.producto_cod"
            :value="productValue"
            :loading="loading"
          />
          <BaseSelect
            v-if="optionsReasons.length === 0"
            ref="macro_motivo_cod"
            id="macro_motivo_cod"
            label="Motivo"
            :selectOptions="formOptions.optionsReasons"
            v-model="form.macro_motivo_cod"
            :value="macro_motivo_codValue"
            :loading="loading"
          />
          <BaseSelect
            v-if="optionsProducts.length > 0"
            ref="product"
            id="producto"
            label="Producto"
            :selectOptions="optionsProducts"
            v-model="form.producto_cod"
            :value="productValue"
            :loading="loading"
          />
          <BaseSelect
            v-if="optionsReasons.length > 0"
            ref="macro_motivo_cod"
            id="macro_motivo_cod"
            label="Motivo"
            :selectOptions="optionsReasons"
            v-model="form.macro_motivo_cod"
            :value="macro_motivo_codValue"
            :loading="loading"
          />
          <BaseSelect
            ref="favorability"
            id="favorability"
            label="Favorabilidad"
            :selectOptions="optionsFavorability"
            v-model="form.a_favor_de"
            :value="favorabilityValue"
            :loading="loading"
          />
          <BaseSelect
            ref="Instancia de recepción"
            id="Instancia de recepción"
            label="Instancia de recepción"
            :selectOptions="formOptions.optionsReception"
            v-model="form.insta_recepcion"
            :loading="loading"
          />
          <BaseSelect
            ref="Canal"
            id="Canal"
            label="Canal"
            :selectOptions="formOptions.optionsChannel"
            v-model="form.canal_cod"
            :loading="loading"
          />
          <BaseSelect
            ref="recepcion-point"
            id="recepcion-point"
            label="Punto de recepción"
            :selectOptions="receptionOptions"
            v-model="form.punto_recepcion"
            :loading="loading"
          />
          <BaseInput
            type="text"
            label="Palabra clave"
            placeholder="Queja, producto o motivo"
            v-model="form.search_key"
          />

          <a-menu mode="horizontal">
            <a-sub-menu>
              <a-button
                slot="title"
                type="primary"
                style="margin-bottom: 16px"
                @click="collapsed = !collapsed"
              >
                <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
              </a-button>
              <a-menu-item
                ><vue-excel-xlsx
                  :loading="loading"
                  :data="complaints"
                  :columns="columnsExcel"
                  file-name="Listado de muestras"
                  sheetName="Listado"
                >
                  <span class="mx-2">Descargar vista actual XLSX</span>
                </vue-excel-xlsx></a-menu-item
              >
              <a-menu-item @click="onGetAllComplaints(false)">
                <vue-excel-xlsx
                  :async="true"
                  :loading="loading"
                  :data="jsondata"
                  :columns="columnsExcel"
                  file-name="Listado de muestras(Completo)"
                  sheetName="Listado"
                >
                  <span class="mx-2">Descargar reporte completo XLSX</span>
                </vue-excel-xlsx>
              </a-menu-item>
              <a-menu-item @click="onGetAllComplaints(true)">
                <vue-excel-xlsx
                  :async="true"
                  :loading="loading"
                  :data="jsoncsv"
                  :columns="columnsExcel"
                  file-type="csv"
                  file-name="Listado de muestras(Completo)"
                  sheetName="Listado"
                >
                  <span class="mx-2">Descargar reporte completo CSV</span>
                </vue-excel-xlsx></a-menu-item
              >
            </a-sub-menu>
          </a-menu>
        </div>
      </div>
      <div
        class="w-full flex justify-end self-end gap-4 my-3 mt-4 mr-2"
        v-show="showFilters"
      >
        <a-button
          class="w-1/2 gap-2 sm:mt-auto mt-3 lg:w-1/6"
          type="danger"
          ghost
          shape="round"
          @click="clearFilters"
        >
          Limpiar
        </a-button>

        <a-button
          :loading="(searching = loading)"
          type="primary"
          shape="round"
          @click="onSearch"
          class="w-1/2 gap-2 sm:mt-auto mt-3 lg:w-1/6"
          :disabled="disableButton && disabled"
        >
          <a-icon type="search" v-if="!searching"></a-icon>
          {{ searching ? "BUSCANDO..." : "BUSCAR" }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["query", "jsondata", "jsoncsv", "complaints"],
  data() {
    return {
      form: {
        creation_date: "",
        identification_number: "",
        criterion_id: "",
        entidad_cod: "",
        tipo_entidad: "",
        identification_type: "",
        search_key: "",
        a_favor_de: "",
        producto_cod: "",
        macro_motivo_cod: "",
        canal_cod: "",
        insta_recepcion: "",
        punto_recepcion: "",
      },
      company: "",
      optionsProducts: [],
      optionsFavorability: [
        { name: "Favorable", id: 1 },
        { name: "Parcialmente Favorable", id: 2 },
        { name: "No Favorable", id: 3 },
      ],
      optionsReasons: [],
      receptionOptions: [],
      productValue: "",
      macro_motivo_codValue: "",
      favorabilityValue: "",
      loading: false,
      collapsed: false,
      disableButton: false,
      showFilters: false,
      columnsExcel: [
        { label: "Muestra", field: "sample_name" },
        { label: "Criterio", field: "criterion_name" },
        { label: "Total en gestión", field: "progress" },
        { label: "Fecha de Creación", field: "created_at" },
      ],
    };
  },
  methods: {
    onSearch() {
      this.$emit("fetch-complaints-samples", this.form);
    },
    clearFilters() {
      this.form = {};
      this.$emit("fetch-complaints-samples", this.form);
    },
    onGetAllComplaints(isCsv = false) {
      this.$emit("download-report-samples", isCsv);
    },
    getCompany() {
      if (this.form.entidad_cod && this.form.entidad_cod !== "") {
        const companyData = this.$store.state.form.optionsCompanies.find(
          (item) =>
            item.sfc_code == this.form.entidad_cod &&
            item.company_type == this.form.tipo_entidad
        );
        this.company = companyData.id + "";
      }
    },
    async getReceptionsPointsOptions() {
      let { data } = await this.$api.getReceptionOptions();
      if (data) {
        this.receptionOptions = data.results;
      }
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    disabled: function () {
      return false;
    },
  },
  created() {
    this.getReceptionsPointsOptions();
  },
  mounted() {
    this.form = { ...this.$props.query };
    setTimeout(() => {
      this.getCompany();
    }, 1500);
  },
  watch: {
    company: async function (value) {
      if (value == "") {
        this.form.entidad_cod = "";
        this.form.tipo_entidad = "";
        this.optionsProducts = [];
        this.optionsReasons = [];
        return;
      }
      this.productValue = "";
      this.macro_motivo_codValue = "";
      let optionsProducts = await this.$store.dispatch(
        "form/loadProductsOptions",
        { company: this.company }
      );

      let optionsReasons = await this.$store.dispatch(
        "form/loadReasonsOptions",
        { company: this.company }
      );
      const companyData = this.$store.state.form.optionsCompanies.find(
        (item) => item.id == this.company
      );
      this.form.entidad_cod = companyData.sfc_code;
      this.form.tipo_entidad = companyData.company_type;
      this.optionsProducts = optionsProducts ? optionsProducts : [];
      this.optionsReasons = optionsReasons ? optionsReasons : [];
    },
  },
};
</script>