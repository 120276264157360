<template>
  <div class="w-full">
    <ComplaintsSamplesFilter
      @fetch-complaints-samples="setQuery"
      :query="start_query"
      :loading="loadingReport"
      @download-report-samples="onGetAllComplaints"
      :jsoncsv="json_csv"
      :jsondata="json_data"
      :complaints="samples"
    />
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
    <div
      v-if="samples.length > 0"
      class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 complaints-samples"
    >
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="samples"
        :pagination="false"
      >
        <div slot="actions" slot-scope="text, record">
          <button
            class="flex justify-center col-span-1 text-xs pr-2 text-blue h-min m-auto"
            @click="complaintsSampleDetails($event, record)"
          >
            <a-icon
              type="eye"
              theme="twoTone"
              two-tone-color="#3366cc"
              :style="{
                fontSize: '20px',
              }"
            />
            <b class="my-auto ml-2">Ver</b>
          </button>
        </div>
      </a-table>
      <div class="p-4 flex justify-end">
        <a-pagination
          v-model="page"
          :page-size="pageSize"
          :page-size-options="pageSizeOptions"
          :total="count"
          show-less-items
          size="large"
        />
      </div>
    </div>
    <div
      v-else-if="loading"
      class="absolute w-full justify-center top-1/3 left-0"
    >
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import ModalConfirmation from "@/components/Dashboard/Users/ModalConfirmation.vue";
import ComplaintsSamplesFilter from "./ComplaintsSamplesFilter";
import { format } from "date-fns";

const columns = [
  {
    title: "No. Muestra",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Fecha de creación",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Tipo de criterio",
    dataIndex: "criterion_name",
    key: "criterion_name",
  },
  {
    title: "Total en gestión",
    dataIndex: "progress",
    key: "progress",
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  components: {
    ModalConfirmation,
    ComplaintsSamplesFilter,
  },
  data() {
    return {
      columns,
      form: {},
      legend: "",
      count: 0,
      samples: [],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      page: 1,
      showModal: false,
      pageSize: 20,
      start_query: {},
      pageSizeOptions: ["20", "40", "60", "80", "100"],
      pageSizeDownload: 10000,
      loadingReport: false,
      json_data: [],

      json_csv: [],
    };
  },
  created() {
    this.undoneQuery();

    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    this.fetchComplaintsSamples();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router
          .replace({
            query: {
              page: this.page,
              creation_date: this.form.creation_date,
              params: this.makeQuery(),
            },
          })
          .catch((error) => error);
      }
    },
    "$route.query.page": function () {
      this.fetchComplaintsSamples();
    },
  },
  methods: {
    complaintsSampleDetails(checked, record) {
      this.$router.push({
        name: "ComplaintsManagementSample",
        query: { sample_id: record.id },
      });
    },
    setQuery(form) {
      this.form = form;
      this.showToast("info", "Buscando resultados...");
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.$router
          .replace({
            query: {
              page: this.page,
              parmas: this.makeQuery(),
            },
          })
          .catch((error) => error);
        this.fetchComplaintsSamples();
      }
      this.page = 1;
    },
    undoneQuery() {
      if (this.$route.query?.params) {
        let queryArray = this.$route.query?.params.split("&");
        queryArray.forEach((element) => {
          let param = element.split("=");
          this.form[param[0]] = param[1];
        });
        this.start_query = this.form;
      }
    },
    makeQuery() {
      if (this.form) {
        let query_params = Object.keys(this.form)
          .map((key) => {
            if (this.form[key] && this.form[key] !== "") {
              return key + "=" + this.form[key];
            }
          })
          .filter((data) => !!data)
          .join("&");
        return query_params;
      }
      return null;
    },
    async fetchComplaintsSamples() {
      let query = `?page_size=${this.pageSize}&page=${this.page}`;
      let query_params = this.makeQuery();
      query += query_params ? `&${query_params}` : "";

      this.loading = true;
      let { error, data } = await this.$api.readSample(query);
      this.loading = false;

      if (error) this.legend = "Ocurrió un error con la consulta";

      if (data) {
        this.count = data.count;
        if (this.count != 0) {
          this.legend = "";
          this.samples = data.results.map((sample) => {
            return {
              ...sample,
              created_at: format(
                new Date(sample.created_at),
                "dd/MM/yyyy hh:mm a"
              ),
              progress: `${sample.progress_sample} de ${sample.sample_quantify} muestras evaluadas`,
            };
          });
        } else {
          this.samples = [];
          this.legend = "No se encontraron resultados";
          this.loading = false;
        }
      }
    },
    onGetAllComplaints(isCsv = false) {
      this.loadingReport = true;

      let pages_all = Math.trunc(this.count / this.pageSizeDownload);
      let mod = this.count % this.pageSizeDownload > 0 ? 1 : 0;
      pages_all += mod;
      const promises = [];
      this.json_data = [];
      this.json_csv = [];
      for (let i = 0; i < pages_all; i++) {
        let query = `?page_size=${this.pageSizeDownload}&page=${i + 1}`;
        let query_params = this.makeQuery();
        query += query_params ? `&${query_params}` : "";
        promises.push(this.$api.readSample(query));
      }
      // Ejecutamos todas las peticiones.
      Promise.allSettled(promises)
        .then((response) => {
          let data = [];
          for (let i = 0; i < pages_all; i++) {
            const complaint_search = response[i]?.value?.data?.results.map(
              (sample) => {
                return {
                  ...sample,
                  created_at: format(
                    new Date(sample.created_at),
                    "dd/MM/yyyy hh:mm a"
                  ),
                  progress: `${sample.progress_sample} de ${sample.sample_quantify} muestras evaluadas`,
                };
              }
            );
            data = [...data, ...complaint_search];
          }

          this.loadingReport = false;
          if (isCsv) this.json_csv = data;
          else this.json_data = data;
        })
        .catch((error) => {
          this.loadingReport = false;
          this.showToast("error", error);
        });
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>
<style>
.complaints-samples .ant-table-thead > tr > th {
  font-weight: bold;
  text-align: center;
  color: #6b7280;
}
.complaints-samples .ant-table-tbody > tr > td {
  text-align: center;
}
</style>
